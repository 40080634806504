import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";

const WriteReview = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ rating, comment: review });
    onClose();
    toast.success("Review submitted successfully");
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-1/3 p-5 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-10">
          <h2 className="text-lg font-bold text-blue font-baskerVille">
            Write a review
          </h2>

          <button type="button" onClick={onClose}>
            <IoMdClose />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-8">
            <label className="block mb-4 text-sm font-bold">
              Write a review:
            </label>
            <textarea
              className="w-full p-2 rounded outline-none resize-none bg-ashColor/20 placeholder:text-ashColor"
              rows="4"
              value={review}
              onChange={handleReviewChange}
              placeholder="Start typing..."
              required
            ></textarea>
          </div>
          <div className="mb-10">
            <label className="block mb-4 text-sm font-bold">Rate store:</label>
            <div className="flex justify-between">
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  size={24}
                  className={`cursor-pointer ${
                    index < rating ? "text-yellow" : "text-gray-300"
                  }`}
                  onClick={() => handleRating(index + 1)}
                />
              ))}
            </div>
          </div>
          <div className="flex">
            <button
              type="submit"
              className="px-4 py-2 text-white rounded bg-blue"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WriteReview;
