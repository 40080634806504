import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/api";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("products/products_list", {
        withCredentials: true,
      });

      return fulfillWithValue(data.products);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get(`/products/product_details/${productId}`, {
        withCredentials: true,
      });
      const { data } = response;

      if (!data.productDetails || !data.productDetails.product_id) {
        throw new Error("Product ID not found in response data");
      }
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchRecommendedProducts = createAsyncThunk(
  "product/fetchRecommendedProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("products/products_list", {
        withCredentials: true,
      });

      return fulfillWithValue(data.products);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTopProducts = createAsyncThunk(
  "product/fetchTopProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/products/top_products", {
        withCredentials: true,
      });

      const topProducts = await Promise.all(
        data.topProducts.map(async (product) => {
          const productDetails = await api.get(
            `/products/product_details/${product.productId}`,
            {
              withCredentials: true,
            }
          );
          console.log(productDetails);
          return productDetails.data.productDetails;
        })
      );

      return fulfillWithValue(topProducts);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const productReducer = createSlice({
  name: "product",
  initialState: {
    products: [],
    topProducts: [],
    recommendedProducts: [],
    productDetails: [],
    loader: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchTopProducts.fulfilled, (state, action) => {
        console.log(action);
        state.loader = false;
        state.topProducts = action.payload;
      })
      .addCase(fetchTopProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.error.message;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.error.message;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loader = false;
        state.productDetails = action.payload.productDetails;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchRecommendedProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchRecommendedProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.recommendedProducts = action.payload;
      })
      .addCase(fetchRecommendedProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.error.message;
      });
  },
});

export default productReducer.reducer;
