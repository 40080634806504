import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const sendOTP = createAsyncThunk(
  "payment/sendOTP",
  async (
    { zippy_wallet_number, pin, name, email, security_answer, service_amount },
    { rejectWithValue }
  ) => {
    try {
      // `/users/add_shipping_address/${userId}
      // /payment/send_otp
      console.log(
        zippy_wallet_number,
        pin,
        name,
        email,
        security_answer,
        service_amount
      );
      const response = await api.post("/payment/send_otp", {
        zippy_wallet_number,
        pin,
        name,
        email,
        security_answer,
        service_amount,
      });
      console.log(response);

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const paymentReducer = createSlice({
  name: "payment",
  initialState: {
    // loading: false,
    // otpSent: false,
    // error: null,
    loader: false,
    errorMessage: "",
    successMessage: "",
  },
  // reducers: {
  //   messageClear: (state) => {
  //     // state.loading = false;
  //     state.otpSent = false;
  //     // state.error = null;
  //     state.errorMessage = "";
  //     state.successMessage = "";
  //   },
  // },
  reducers: {
    resetPaymentState: (state) => {
      state.otpSent = false;
      state.error = "";
      state.success = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOTP.pending, (state) => {
        state.loader = true;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loader = false;
        state.otpSent = true;
        state.success = action.payload.message;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload.message || "An error occurred";
      });
  },
});

export const { resetPaymentState } = paymentReducer.actions;
export default paymentReducer.reducer;
