// import React from "react";
// import { addShippingAddress } from "../store/reducers/shippingReducer";
// import Button from "../components/Button";

// const ShippingAddressModal = ({ closeModal }) => {

//   return (
//     <div
//       id="addressModal"
//       className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
//     >
//       <div className="bg-white p-8 rounded w-[500px]">
//         <div className="relative mb-8">
//           <h2 className="text-lg font-bold font-baskerVille text-blue">
//             Shipping Address
//           </h2>
//           <button
//             className="absolute right-0 text-gray-700 top-1"
//             onClick={closeModal}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               viewBox="0 0 24 24"
//               fill="none"
//             >
//               <path
//                 d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
//                 fill="black"
//               />
//             </svg>
//           </button>
//         </div>
//         <div className="mb-8">
//           <label
//             htmlFor="contact_person"
//             className="block text-sm font-bold text-gray-700"
//           >
//             Contact person:
//           </label>
//           <input
//             type="text"
//             id="contact_person"
//             name="contact_person"
//             className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
//             placeholder="John doe..."
//           />
//         </div>
//         <div className="mb-8">
//           <label
//             htmlFor="phone_number"
//             className="block text-sm font-bold text-gray-700"
//           >
//             Phone number:
//           </label>
//           <input
//             type="text"
//             id="phone_number"
//             name="phone_number"
//             className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
//             placeholder="080419419419"
//           />
//         </div>
//         <div className="mb-8">
//           <label
//             htmlFor="full_address"
//             className="block text-sm font-bold text-gray-700"
//           >
//             Full Address:
//           </label>
//           <textarea
//             id="full_address"
//             name="full_address"
//             className="w-full p-2 mt-1 text-black border rounded outline-none resize-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
//             placeholder="Express..."
//             rows="3"
//           ></textarea>
//         </div>
//         <div className="w-2/5">
//           <Button buttonType={"button"} buttonName={"Save address"} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ShippingAddressModal;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addShippingAddress } from "../store/reducers/shippingReducer";
import Button from "../components/Button";

const ShippingAddressModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const userId = userInfo.userId;
  console.log(userId);
  const [formData, setFormData] = useState({
    contact_person: "",
    phonenumber: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "Nigeria", // Default to Nigeria
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      userId, // Include userId in the formData object
    };
    dispatch(addShippingAddress(data)).then((res) => {
      if (!res.error) {
        closeModal();
      }
    });
  };

  return (
    <div
      id="addressModal"
      className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
    >
      <div className="bg-white p-8 rounded w-[500px]">
        <div className="relative mb-8">
          <h2 className="text-lg font-bold font-baskerVille text-blue">
            Shipping Address
          </h2>
          <button
            className="absolute right-0 text-gray-700 top-1"
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="contact_person"
              className="block text-sm font-bold text-gray-700"
            >
              Contact person:
            </label>
            <input
              type="text"
              id="contact_person"
              name="contact_person"
              value={formData.contact_person}
              onChange={handleChange}
              className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
              placeholder="John doe..."
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phonenumber"
              className="block text-sm font-bold text-gray-700"
            >
              Phone number:
            </label>
            <input
              type="text"
              id="phonenumber"
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleChange}
              className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
              placeholder="080419419419"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="block text-sm font-bold text-gray-700"
            >
              Full Address:
            </label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full p-2 mt-1 text-black border rounded outline-none resize-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
              placeholder="Araromi Olalomi street..."
              rows="3"
              required
            ></textarea>
          </div>
          <div className="flex gap-8">
            <div className="mb-4">
              <label
                htmlFor="city"
                className="block text-sm font-bold text-gray-700"
              >
                City:
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
                placeholder="Abeokuta"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="state"
                className="block text-sm font-bold text-gray-700"
              >
                State:
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
                placeholder="Ogun State"
                required
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="mb-4">
              <label
                htmlFor="postal_code"
                className="block text-sm font-bold text-gray-700"
              >
                Postal Code:
              </label>
              <input
                type="text"
                id="postal_code"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleChange}
                className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
                placeholder="10001"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block text-sm font-bold text-gray-700"
              >
                Country:
              </label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full p-2 mt-1 text-black border rounded outline-none bg-grayColor bg-opacity-8 border-grayColor border-opacity-16"
                placeholder="Nigeria"
                required
              />
            </div>
          </div>
          <Button buttonType="submit" buttonName="Save address" />
        </form>
      </div>
    </div>
  );
};

export default ShippingAddressModal;
