import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const user_register = createAsyncThunk(
  "auth/user_register",
  async (userdetails, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/register_user", userdetails, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const user_login = createAsyncThunk(
  "auth/user_login",
  async (credentials, { fulfillWithValue, rejectWithValue }) => {
    try {
      console.log(credentials);
      const response = await api.post("/users/sign_in", credentials, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      const { data } = response;
      localStorage.setItem("userId", data.user.id);
      localStorage.setItem("username", data.user.username);
      localStorage.setItem("phonenumber", data.user.phonenumber);
      localStorage.setItem("email", data.user.email);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authReducer = createSlice({
  name: "auth",
  initialState: {
    loader: false,
    userInfo: "",
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(user_register.pending, (state) => {
        state.loader = true;
      })
      .addCase(user_register.rejected, (state, { payload }) => {
        state.errorMessage = payload.message;
        state.loader = false;
      })
      .addCase(user_register.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
      })
      .addCase(user_login.pending, (state) => {
        state.loader = true;
      })
      .addCase(user_login.rejected, (state, { payload }) => {
        state.errorMessage = payload.message;
        state.loader = false;
      })
      .addCase(user_login.fulfilled, (state, { payload }) => {
        const userInfo = {
          userId: localStorage.getItem("userId"),
          username: localStorage.getItem("username"),
          phonenumber: localStorage.getItem("phonenumber"),
          email: localStorage.getItem("email"),
        };
        state.loader = false;
        state.successMessage = payload.message;
        state.userInfo = userInfo;
      });
  },
});

export const { messageClear } = authReducer.actions;
export default authReducer.reducer;
