import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShippingAddress,
  messageClear,
} from "../store/reducers/shippingReducer";
import { sendOTP, resetPaymentState } from "../store/reducers/paymentReducer";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import ShippingAddressModal from "./ShippingAddressModal";
import toast from "react-hot-toast";

const CheckOut = () => {
  const dispatch = useDispatch();

  const { errorMessage, successMessage, shippingAddresses } = useSelector(
    (state) => state.shipping
  );

  const { error, success, otpSent, loader } = useSelector(
    (state) => state.payment
  );

  const { userInfo } = useSelector((state) => state.auth);

  const subtotal = useSelector((state) => state.cart.subtotal);
  const formattedSubtotal = subtotal.toLocaleString();

  console.log(shippingAddresses);
  const [isZippyWalletSelected, setIsZippyWalletSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zippyWalletNumber, setZippyWalletNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [otp, setOtp] = useState("");

  const toggleZippyWalletFields = () => {
    setIsZippyWalletSelected((prev) => !prev);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();

    const paymentData = {
      zippy_wallet_number: zippyWalletNumber,
      name: name,
      email: email,
      pin: pin,
      security_answer: securityAnswer,
      service_amount: subtotal,
    };

    dispatch(sendOTP(paymentData));
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    // Implement API call for debit wallet here using OTP
    console.log("Submit OTP:", otp);
  };

  useEffect(() => {
    dispatch(fetchShippingAddress(userInfo.userId));
  }, [dispatch, userInfo.userId]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch(resetPaymentState());
    }
    if (error) {
      toast.error(error);
      dispatch(resetPaymentState());
    }
  }, [success, error, dispatch]);

  const renderAddress = (address) => (
    <div key={address.id}>
      <p>{address.contact_person}</p>
      <p>{address.phonenumber}</p>
      <p>{`${address.address}, ${address.city}, ${address.state}, ${address.country}`}</p>
    </div>
  );

  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12 font-nunito">
        {/* Shopping Cart Items (col-span-8) */}
        <main className="grid col-span-12 gap-4 md:col-span-8">
          {/* Shopping Cart Header */}
          <div className="p-6 bg-white rounded">
            <h2 className="mb-4 text-xl font-bold font-baskerVille">
              Shopping Cart
            </h2>
            <div className="flex justify-between">
              <div className="font-semibold rounded-lg">
                {shippingAddresses && shippingAddresses.length > 0 ? (
                  shippingAddresses.map(renderAddress)
                ) : (
                  <p>No shipping address available.</p>
                )}
              </div>
              <Link href="#" className="self-end text-blue" onClick={openModal}>
                Change
              </Link>
            </div>
          </div>

          {/* Form */}
          <div className="px-6 py-8 bg-white rounded">
            <h2 className="mb-4 text-xl font-bold font-baskerVille">
              Payment options
            </h2>

            {/* Pay with Zippy Wallet */}
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="payment_option"
                id="zippy_wallet"
                value="zippy_wallet"
                onChange={toggleZippyWalletFields}
              />
              <label htmlFor="zippy_wallet" className="ml-2">
                Pay with Zippy Wallet :
              </label>
            </div>

            {/* Zippy Wallet Fields */}
            <form onSubmit={handleSendOTP}>
              <div
                id="zippy_wallet_fields"
                className={isZippyWalletSelected ? "" : "hidden"}
              >
                <div className="mr-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="zippy_wallet_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zippy Wallet Number:
                  </label>
                  <input
                    type="text"
                    id="zippy_wallet_number"
                    name="zippy_wallet_number"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="08012342419"
                    value={zippyWalletNumber}
                    onChange={(e) => setZippyWalletNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Account Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="Harry Maguire"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="mr-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="harrymaguire@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="pin"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pin:
                  </label>
                  <input
                    type="password"
                    id="pin"
                    name="pin"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="XXXX"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="security_answer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Security Answer:
                  </label>
                  <input
                    type="password"
                    id="security_answer"
                    name="security_answer"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="Security Answer"
                    value={securityAnswer}
                    onChange={(e) => setSecurityAnswer(e.target.value)}
                    required
                  />

                  {/* OTP Input and Submit Button */}
                  {otpSent && (
                    <div className="flex flex-col md:inline-block md:w-2/5">
                      <label
                        htmlFor="otp"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Enter OTP:
                      </label>
                      <input
                        type="text"
                        id="otp"
                        name="otp"
                        className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      <Button
                        text={loader ? "Processing..." : "Submit OTP"}
                        buttonType="submit"
                        className="mt-4"
                        disabled={loader}
                        onClick={handleSubmitOtp}
                      />
                    </div>
                  )}
                </div>

                {/* <Button buttonType={"button"} buttonName={"Submit"} /> */}
                <button onClick={handleSendOTP} disabled={loader}>
                  {loader ? "Sending OTP..." : "Send OTP"}
                </button>
              </div>
            </form>

            {/* Pay with Debit Card */}
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="payment_option"
                id="debit_card"
                value="debit_card"
                onChange={toggleZippyWalletFields}
              />
              <label htmlFor="debit_card" className="ml-2">
                Pay with Debit Card
              </label>
            </div>

            {/* Pay with Bank Transfer */}
            <div className="flex items-center">
              <input
                type="radio"
                name="payment_option"
                id="bank_transfer"
                value="bank_transfer"
                onChange={toggleZippyWalletFields}
              />
              <label htmlFor="bank_transfer" className="ml-2">
                Pay with Bank Transfer
              </label>
            </div>
          </div>
        </main>

        {/* Order Summary */}
        <aside className="col-span-12 p-8 bg-white rounded md:col-span-4 h-max">
          <h2 className="mb-4 text-xl font-bold font-baskerVille">Checkout</h2>

          {/* Subtotal Price */}
          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Subtotal:</span>
            <p className="text-lg">{`₦${formattedSubtotal}`}</p>
          </div>

          {/* Shipping Fee */}
          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Shipping fee:</span>
            <p className="text-lg">
              <p>Delivery fees not included yet</p>
            </p>
          </div>

          {/* Total Price */}
          <div className="flex items-center justify-between py-4 mb-4 font-semibold border-y border-ashColor border-opacity-32">
            <span>Total:</span>
            <p className="text-lg">{`₦${formattedSubtotal}`}</p>
          </div>
          {/* Checkout Button */}
          <Link to="/thank-you" className="w-full">
            <Button buttonType={"button"} buttonName={"Checkout"} fullWidth />
          </Link>
        </aside>
      </div>

      {/* Shipping Address Modal */}
      {isModalOpen && <ShippingAddressModal closeModal={closeModal} />}
    </div>
  );
};

export default CheckOut;
