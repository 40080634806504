// export const transformGoogleDriveLink = (url) => {
//   if (typeof url !== "string") {
//     console.error("Invalid URL type:", url);
//     return url;
//   }

//   const match = url.match(/\/d\/([a-zA-Z0-9_-]+)\//);
//   if (match) {
//     const fileId = match[1];
//     return `https://drive.google.com/uc?export=view&id=${fileId}`;
//   } else {
//     console.warn("Could not transform URL:", url);
//     return url;
//   }
// };

export const transformGoogleDriveLink = (pictureObject) => {
  if (typeof pictureObject === "object" && pictureObject !== null) {
    const url = pictureObject.picture1; // Change this if you need to use a different picture key
    const match = url.match(/\/d\/(.*?)\//);
    if (match && match[1]) {
      return `https://drive.google.com/thumbnail?id=${match[1]}`;
    }
    return url; // Return the original URL if it doesn't match the pattern
  }
  return pictureObject;
};
