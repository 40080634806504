import { Link } from "react-router-dom";

const ProductCard = ({
  productId,
  productImage,
  productName,
  productPrice,
  itemLeft,
}) => {
  return (
    <div className="p-1 transition-shadow duration-200 bg-white border rounded-lg border-borderLine hover:shadow-md">
      {/* <Link
        to={`/productDetails/${productName.replace(/\s+/g, "-").toLowerCase()}`}
      > */}

      <Link to={`/productDetails/${productId}`}>
        <div>
          <img
            src={productImage}
            alt={productName}
            className="w-full h-[150px] sm:h-[180px] object-cover rounded-md hover:scale-95 transition-transform duration-200"
          />
          <div className="px-1 py-2">
            <p className="text-sm font-normal capitalize truncate transition-colors duration-200 font-baskerVille text-black/50 hover:text-black">
              {productName}
            </p>
            <div className="flex items-center justify-between pt-1">
              <p className="text-sm font-bold">{productPrice} naira</p>
              {itemLeft > 0 ? (
                <p className="text-sm font-bold text-ashColor">
                  Items Left: {itemLeft}
                </p>
              ) : (
                <p className="text-sm font-bold text-orange_red">
                  Out of Stock
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
