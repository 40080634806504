import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_register, messageClear } from "../store/reducers/authReducer";
import toast from "react-hot-toast";
import { FadeLoader } from "react-spinners";

const Register = () => {
  const { loader, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  const [state, setState] = useState({
    username: "",
    phonenumber: "",
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const register = (e) => {
    e.preventDefault();
    dispatch(user_register(state));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  return (
    <div>
      {loader && (
        <div className="fixed top-0 left-0 w-screen h-screen  flex items-center justify-center bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}
      <div className="flex items-center justify-center min-h-screen bg-white font-nunito">
        <form onSubmit={register}>
          <div className="max-w-md p-8">
            <h2 className="mb-3 text-2xl font-bold text-center">
              Welcome to Marketplace
            </h2>

            <p className="mb-8 text-center text-grayColor">
              Type in your zippywallet number, email and password to create a
              Marketplace account:
            </p>

            {/* Username Input Box */}
            <input
              type="text"
              name="username"
              onChange={inputHandle}
              value={state.username}
              placeholder="Username"
              className="w-full px-4 py-3 mb-6 border rounded-md focus:outline-none focus:border-blue"
              required
            />

            {/* ZippyWallet Number Input Box */}
            <input
              type="text"
              name="phonenumber"
              onChange={inputHandle}
              value={state.phonenumber}
              placeholder="Zippyworld Wallet number"
              className="w-full px-4 py-3 mb-6 border rounded-md focus:outline-none focus:border-blue"
              required
            />

            {/* Email Input Box */}
            <input
              type="email"
              name="email"
              onChange={inputHandle}
              value={state.email}
              placeholder="Email Address"
              className="w-full px-4 py-3 mb-6 border rounded-md focus:outline-none focus:border-blue"
              required
            />

            {/* Password Input Box */}
            <input
              type="password"
              name="password"
              onChange={inputHandle}
              value={state.password}
              placeholder="Password"
              className="w-full px-4 py-3 mb-6 border rounded-md focus:outline-none focus:border-blue"
              required
            />

            {/* Button */}
            <button
              type="submit"
              className="w-full px-4 py-3 bg-blue text-white rounded-md"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
