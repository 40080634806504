import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { user_login, messageClear } from "../store/reducers/authReducer";
import toast from "react-hot-toast";
import { FadeLoader } from "react-spinners";

const Login = () => {
  const { loader, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [isTyping, setIsTyping] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setIsTyping(true);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const login = (e) => {
    e.preventDefault();
    dispatch(user_login(state));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      // localStorage.setItem("userId", user.id);
      dispatch(messageClear());
      navigate("/");
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch, navigate]);

  return (
    <div>
      {loader && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}
      <div className="flex items-center justify-center min-h-screen bg-white font-nunito">
        <form onSubmit={login} className="w-full max-w-lg p-8">
          <h2 className="mb-3 text-2xl font-bold text-center">
            Welcome to Marketplace
          </h2>
          <p className="mb-8 text-center text-grayColor">
            Log back into your Marketplace account.
          </p>
          {/* Email Input Box */}
          <div className="relative mb-6">
            <input
              type="email"
              name="email"
              placeholder="Opoolaharbudul@gmail.com"
              className={`w-full px-6 py-4 font-medium text-black placeholder-black bg-gray-200 rounded-md focus:outline-none focus:border-gray-500 ${
                isTyping ? "" : "text-gray-500"
              }`}
              onChange={handleInputChange}
              value={state.email}
              required
            />
            <Link
              to={""}
              className={`absolute text-sm font-medium transform -translate-y-1/2 right-4 top-1/2 ${
                isTyping ? "text-blue" : "text-gray-500"
              }`}
            >
              Edit
            </Link>
          </div>
          {/* Password Input Box */}
          <div className="relative mb-6">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              className="w-full px-6 py-4 border-2 border-borderColor rounded-md focus:outline-none text-black placeholder-[#B3B3B3]"
              onChange={handleInputChange}
              value={state.password}
              required
            />
            <span
              className="absolute text-xs transform -translate-y-1/2 cursor-pointer right-4 top-1/2"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"
                    fill="#B3B3B3"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="23"
                    y2="23"
                    stroke="#B3B3B3"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"
                    fill="#B3B3B3"
                  />
                </svg>
              )}
            </span>
          </div>
          {/* Login Button */}
          <Button buttonType={"submit"} buttonName={"Login"} fullWidth />
          {/* Forgot Password Link */}
          <p className="mt-4 font-bold text-center">
            <Link to={"/forgotpassword"} className="text-blue">
              Forgot your password?
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
