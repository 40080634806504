import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const addShippingAddress = createAsyncThunk(
  "shipping/addShippingAddress",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    console.log(data);
    try {
      const { userId, ...addressData } = data;
      const response = await api.post(
        `/users/add_shipping_address/${userId}`,
        addressData,
        {
          withCredentials: true,
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchShippingAddress = createAsyncThunk(
  "shipping/fetchShippingAddress",
  async (userId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get(`/users/shipping_addresses/${userId}`);
      console.log(response);
      return fulfillWithValue(response.data.addresses);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  shippingAddresses: [],
  loader: false,
  errorMessage: "",
  successMessage: "",
};

const shippingReducer = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(addShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.shippingAddresses.push(action.payload);
      })
      .addCase(addShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.shippingAddresses = action.payload;
      })
      .addCase(fetchShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      });
  },
});

export const { messageClear } = shippingReducer.actions;

export default shippingReducer.reducer;
