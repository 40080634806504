import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import ProductCard from "../components/productCard"; // Fixed import name casing
import {
  fetchProductById,
  fetchRecommendedProducts,
} from "../store/reducers/productReducer";
import { generateRandomColor } from "../utilities/generateRandomColor";
import CustomerReviews from "../components/customerReviews";
import ProductRating from "../components/productRating";
import { addToCart, messageClear } from "./../store/reducers/cartReducer";
import toast from "react-hot-toast";
import userEvent from "@testing-library/user-event";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";

const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.product.productDetails);
  const { errorMessage, successMessage } = useSelector((state) => state.cart);

  const recommendedProducts = useSelector(
    (state) => state.product.recommendedProducts
  );
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    dispatch(fetchProductById(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(fetchRecommendedProducts());
  }, [dispatch]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  const StarIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="#FFD700"
    >
      <path d="M12 .587l3.668 7.425 8.2 1.193-5.934 5.788 1.4 8.168L12 18.896l-7.334 3.865 1.4-8.168-5.934-5.788 8.2-1.193z" />
    </svg>
  );

  const PercentageDisplay = ({ percentage }) => {
    const fullStars = Math.floor((percentage / 100) * 5);
    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} />);
    }

    return (
      <div className="flex items-center gap-2 pb-1">
        <div className="flex">{stars}</div>
        <span className="text-sm font-bold">{percentage}%</span>
      </div>
    );
  };

  const addCart = (productId) => {
    dispatch(
      addToCart({
        product_id: productId,
        quantity,
        user_id: localStorage.getItem("userId"),
        merchant_id: productDetails.merchant_id,
      })
    );
  };

  const productPictureUrl = productDetails.product_picture
    ? transformGoogleDriveLink(productDetails.product_picture)
    : `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
        productDetails.product_name
      }`;

  // console.log("Transformed Product Image URL:", productPictureUrl);

  return (
    <div>
      <TopBar />
      <div className="py-10 mx-4 sm:mx-10">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-4">
            <div>
              <img
                src={productPictureUrl}
                alt={productDetails.product_name}
                className="object-cover rounded-lg aspect-square"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-5">
            <p className="pb-8 text-3xl font-bold font-baskerville">
              {productDetails.product_name}
            </p>
            <div>
              <p className="text-base font-bold">Store name:</p>
              <Link to="/stores">
                <p className="text-sm underline text-blue">
                  Sell and Swap Store
                </p>
              </Link>
            </div>

            <div className="py-3">
              <p className="text-base font-bold">Product Rating:</p>
              <span className="text-sm font-medium">4.5 out of 10 </span>
              <span className="text-sm text-black/50">(123 ratings)</span>
            </div>
            <div>
              <p className="text-base font-bold">Product description:</p>
              <span className="text-sm text-justify">
                {productDetails.product_description}
              </span>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="px-3 py-5 bg-white border rounded-lg border-borderLine">
              <p className="text-base font-bold font-baskerville">
                Price <span className="text-blue">(naira)</span>
              </p>
              <span className="text-xl font-bold text-blue">
                {`₦${productDetails.product_price}`}
              </span>{" "}
              <div className="pt-7">
                <p className="pb-1 text-sm">Quantity</p>
                <div className="">
                  <input
                    className="bg-[#C4C4C4]/20 text-gray-700 py-3 px-4 rounded-[5px] w-full text-sm font-normal outline-none"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    type="number"
                    min="1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 pt-4 pb-8 gap-x-2">
                <div className="col-span-8 rounded">
                  <Button
                    onClick={() => addCart(productId)}
                    buttonType={""}
                    fullWidth
                    buttonName={
                      <div className="flex items-center gap-x-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M5.37708 16.9813C5.00473 16.9813 4.6873 16.8487 4.4248 16.5835C4.1623 16.3184 4.03105 15.9996 4.03105 15.6273C4.03105 15.2549 4.16233 14.9375 4.42488 14.675C4.68744 14.4125 5.00307 14.2813 5.37175 14.2813C5.74045 14.2813 6.05918 14.4138 6.32793 14.679C6.59668 14.9441 6.73105 15.2629 6.73105 15.6352C6.73105 16.0076 6.59848 16.325 6.33333 16.5875C6.06817 16.85 5.74942 16.9813 5.37708 16.9813ZM12.8771 16.9813C12.5047 16.9813 12.1873 16.8487 11.9248 16.5835C11.6623 16.3184 11.5311 15.9996 11.5311 15.6273C11.5311 15.2549 11.6623 14.9375 11.9249 14.675C12.1874 14.4125 12.5031 14.2813 12.8718 14.2813C13.2405 14.2813 13.5592 14.4138 13.8279 14.679C14.0967 14.9441 14.2311 15.2629 14.2311 15.6352C14.2311 16.0076 14.0985 16.325 13.8333 16.5875C13.5682 16.85 13.2494 16.9813 12.8771 16.9813ZM5.19355 13.0813C4.66855 13.0813 4.29043 12.9062 4.05918 12.5563C3.82793 12.2063 3.83105 11.8125 4.06855 11.375L5.26855 9.1625L2.41855 3.125H1.4998C1.3498 3.125 1.22168 3.06875 1.11543 2.95625C1.00918 2.84375 0.956055 2.70938 0.956055 2.55313C0.956055 2.39688 1.00996 2.26562 1.11777 2.15938C1.22559 2.05313 1.35918 2 1.51855 2H2.79355C2.90605 2 3.0073 2.02772 3.0973 2.08316C3.1873 2.13859 3.2548 2.21713 3.2998 2.31875L3.84355 3.48125H14.8873C15.2873 3.48125 15.5404 3.60313 15.6467 3.84688C15.7529 4.09063 15.7186 4.3625 15.5436 4.6625L13.0123 9.21875C12.8873 9.43125 12.7123 9.61563 12.4873 9.77188C12.2623 9.92813 12.0186 10.0063 11.7561 10.0063H6.0748L5.02481 11.9563H13.6873C13.8373 11.9563 13.9654 12.0125 14.0717 12.125C14.1779 12.2375 14.2311 12.3719 14.2311 12.5281C14.2311 12.6844 14.1771 12.8156 14.0693 12.9219C13.9615 13.0281 13.8279 13.0813 13.6686 13.0813H5.19355Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>Add to cart</span>
                      </div>
                    }
                  />
                </div>
                <div className="col-span-4">
                  <button className="text-blue text-sm font-normal bg-[#D9EAF6] rounded-[5px] py-3 px-2 w-full">
                    Buy Now!
                  </button>
                </div>
              </div>
              <hr className="space-x-4 border-dotted border-ashColor/50 border-t-1" />
              <div className="pb-3 text-sm text-center pt-7">
                <span>
                  If you have any problems with this product, please contact the
                  seller
                </span>
                <span className="mx-1 font-semibold text-blue">
                  {productDetails.merchantName}
                </span>
                <span>on</span>
                <span className="mx-1 font-semibold text-blue">
                  {productDetails.merchantNumber}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 pt-12">
          <ProductRating />

          <CustomerReviews productId={productId} />
        </div>

        <div className="pt-8">
          <p className="pb-4 text-2xl font-bold font-baskerville">
            Recommended For You
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
