// CustomerReviews.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviews, submitReview } from "../store/reducers/reviewReducer";
import WriteReview from "./writeReview";
import { formatDate } from "../utilities/formatDate";

const CustomerReviews = ({ productId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.reviews.reviews);
  const reviewStatus = useSelector((state) => state.reviews.status);
  const error = useSelector((state) => state.reviews.error);

  useEffect(() => {
    // debugger;
    if (reviewStatus === "idle") {
      dispatch(fetchReviews(productId));
    }
  }, [reviewStatus, dispatch, productId]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (review) => {
    dispatch(submitReview({ ...review, merchantId: 5, productId, userId: 1 }));
  };

  return (
    <div className="col-span-12 sm:col-span-9">
      <div className="bg-white rounded-lg">
        <div className="flex justify-between px-5 pt-5 pb-4 border-b border-borderLine">
          <p className="text-sm font-baskerVille">Customer Reviews</p>
          <button
            onClick={openModal}
            className="text-xs font-semibold text-blue"
          >
            Write a Review
          </button>
        </div>
        <div className="p-5">
          {reviewStatus === "loading" && <p>Loading reviews...</p>}
          {reviewStatus === "succeeded" &&
            reviews.length > 0 &&
            reviews.map((review) => (
              <div key={review.review_id} className="pb-5">
                <span className="text-sm font-semibold text-blue">
                  {review.username}:{" "}
                </span>
                <span className="text-sm text-blue/50">
                  {formatDate(review.created_at)}
                </span>
                <div className="py-1">
                  <span className="text-sm text-blue/50">Rating: </span>
                  <span className="text-sm font-semibold text-blue">
                    {`${review.rating}.0`}
                  </span>
                </div>
                <span className="text-sm">{review.comment}</span>
              </div>
            ))}
          {reviewStatus === "succeeded" && reviews.length === 0 && (
            <p>Product does not have a review</p>
          )}
          {reviewStatus === "failed" && <p>{error}</p>}
        </div>
      </div>
      <WriteReview
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={(review) => handleSubmit(review)}
      />
    </div>
  );
};

export default CustomerReviews;
