import { useEffect, useState } from "react";
import slider1 from "../assets/images/sliderImage/slider1.png";
import slider2 from "../assets/images/sliderImage/slider2.png";
import slider3 from "../assets/images/sliderImage/slider3.png";

const Slider = () => {
  const sliderImages = [slider1, slider2, slider3];
  const [sliderNumber, setSliderNumber] = useState(0);
  function increaseSlider() {
    setSliderNumber((sliderNumber + 1) % sliderImages.length);
  }

  useEffect(() => {
    const interval = setInterval(increaseSlider, 5000);
    return () => clearInterval(interval);
  }, [sliderNumber]);
  return (
    <div className="relative">
      <img
        src={sliderImages[sliderNumber]}
        alt="Delicious Food"
        className="w-full rounded-lg"
      />
      <div className="absolute inset-0 flex items-center justify-between px-3 text-center text-red-700">
        <button
          onClick={() =>
            setSliderNumber(
              (sliderNumber - 1 + sliderImages.length) % sliderImages.length
            )
          }
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M11.6999 16.0001L17.4666 21.7667L18.8666 20.3334L14.5333 16.0001L18.8666 11.6667L17.4666 10.2334L11.6999 16.0001ZM2.66659 16.0001C2.66659 14.1779 3.01659 12.4556 3.71659 10.8334C4.41659 9.21119 5.37214 7.79453 6.58325 6.58341C7.79436 5.3723 9.21103 4.41675 10.8333 3.71675C12.4555 3.01675 14.1777 2.66675 15.9999 2.66675C17.8444 2.66675 19.5777 3.01675 21.1999 3.71675C22.8221 4.41675 24.2333 5.3723 25.4333 6.58341C26.6333 7.79453 27.5833 9.21119 28.2833 10.8334C28.9833 12.4556 29.3333 14.1779 29.3333 16.0001C29.3333 17.8445 28.9833 19.5779 28.2833 21.2001C27.5833 22.8223 26.6333 24.2334 25.4333 25.4334C24.2333 26.6334 22.8221 27.5834 21.1999 28.2834C19.5777 28.9834 17.8444 29.3334 15.9999 29.3334C14.1777 29.3334 12.4555 28.9834 10.8333 28.2834C9.21103 27.5834 7.79436 26.6334 6.58325 25.4334C5.37214 24.2334 4.41659 22.8223 3.71659 21.2001C3.01659 19.5779 2.66659 17.8445 2.66659 16.0001Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
        <button onClick={() => increaseSlider()}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M20.3001 15.9999L14.5334 10.2333L13.1334 11.6666L17.4667 15.9999L13.1334 20.3333L14.5334 21.7666L20.3001 15.9999ZM29.3334 15.9999C29.3334 17.8221 28.9834 19.5444 28.2834 21.1666C27.5834 22.7888 26.6279 24.2055 25.4167 25.4166C24.2056 26.6277 22.789 27.5833 21.1667 28.2833C19.5445 28.9833 17.8223 29.3333 16.0001 29.3333C14.1556 29.3333 12.4223 28.9833 10.8001 28.2833C9.17786 27.5833 7.76675 26.6277 6.56675 25.4166C5.36675 24.2055 4.41675 22.7888 3.71675 21.1666C3.01675 19.5444 2.66675 17.8221 2.66675 15.9999C2.66675 14.1555 3.01675 12.4221 3.71675 10.7999C4.41675 9.1777 5.36675 7.76659 6.56675 6.56659C7.76675 5.36659 9.17786 4.41659 10.8001 3.71659C12.4223 3.01659 14.1556 2.66659 16.0001 2.66659C17.8223 2.66659 19.5445 3.01659 21.1667 3.71659C22.789 4.41659 24.2056 5.36659 25.4167 6.56659C26.6279 7.76659 27.5834 9.1777 28.2834 10.7999C28.9834 12.4221 29.3334 14.1555 29.3334 15.9999Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};
export default Slider;
