import { Link } from "react-router-dom";

const StoreCategories = ({ storeName, storeIcon, storeLink }) => {
  return (
    <div className="">
      <Link
        to={storeLink}
        className="flex items-center gap-x-4 mb-7 hover:scale-95"
      >
        <span>{storeIcon}</span>
        <span className="text-base font-medium">{storeName}</span>
      </Link>
    </div>
  );
};

export default StoreCategories;

// StoreCategories.js
// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchCategories } from "../store/reducers/homeReducer";

// const StoreCategories = ({ storeName, storeLink, storeIcon }) => {
//   const dispatch = useDispatch();
//   const { categories, loader, errorMessage } = useSelector(
//     (state) => state.home
//   );

//   useEffect(() => {
//     dispatch(fetchCategories());
//   }, [dispatch]);

//   if (loader) {
//     return <div>Loading...</div>;
//   }

//   if (errorMessage) {
//     return <div>Error: {errorMessage}</div>;
//   }

//   return (
//     <div>
//       <span>{storeName}</span>
//       <ul>
//         {categories.map((category) => (
//           <li key={category.id}>{category.name}</li>
//         ))}
//       </ul>
//       <Link to={storeLink}>{storeIcon}</Link>
//     </div>
//   );
// };

// export default StoreCategories;
