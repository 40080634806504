import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import InputField from "./inputField";
import zippyLogo from "../assets/images/zippylogo.png";
import { IoIosArrowDown } from "react-icons/io";
import { RiShoppingCart2Line, RiUser3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartItems } from "../store/reducers/cartReducer";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { cart_products_count, cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const redirect_cart_page = () => {
    if (userInfo) {
      navigate("/cart");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    dispatch(fetchCartItems(userInfo.userId));
  }, [dispatch]);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  return (
    <div className="border-b border-black/10">
      <div className="grid grid-cols-3 py-4 mx-4 sm:mx-10">
        <Link to="/">
          <div className="flex items-center justify-start">
            <img src={zippyLogo} alt="ZippyWorld" />
            <span className="text-lg font-bold text-blue ms-4 font-baskerVille">
              Marketplace.
            </span>
          </div>
        </Link>
        <div className="justify-center hidden sm:flex gap-x-2">
          <InputField
            inputType={"text"}
            placeholder={"Search marketplace..."}
          />
          <Button buttonName={"Search"} buttonType={"button"} />
        </div>
        <div className="justify-end hidden sm:flex">
          <div className="relative flex items-center mr-4">
            <span className="text-blue">
              <RiUser3Line size={20} />
            </span>
            <span className="text-blue font-semibold text-[16px] mx-2">
              {userInfo ? `Welcome, ${userInfo.username}` : "Welcome, Guest"}
            </span>
            {!userInfo && (
              <>
                <span
                  className="cursor-pointer text-blue"
                  onClick={toggleDropdown}
                >
                  <IoIosArrowDown size={20} />
                </span>
                {dropdownVisible && (
                  <div className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-7">
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="flex items-center justify-center mr-2">
            <span
              className="text-blue flex items-center"
              onClick={redirect_cart_page}
            >
              <Link aria-label="Cart">
                <RiShoppingCart2Line size={24} aria-hidden="true" />
              </Link>
              {cart_products_count.length !== 0 && (
                <div className="w-4 h-4 p-2 text-blue rounded-full flex items-center justify-center">
                  {cartItems.length || cart_products_count}
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
