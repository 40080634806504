const InputField = ({
  inputName,
  inputType,
  inputOnChange,
  inputOnBlur,
  inputValue,
  placeholder,
  labelName,
}) => {
  return (
    <div className="w-full">
      <p className="text-sm">{labelName}</p>

      <input
        onChange={inputOnChange}
        value={inputValue}
        onBlur={inputOnBlur}
        type={inputType}
        name={inputName}
        className="bg-grayColor/16 border border-ashColor/24 text-gray-700 py-3 px-4 rounded-[5px] w-full text-sm font-normal outline-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
