// reviewSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk for fetching reviews
export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/reviews/fetch_review/${productId}`, {
        withCredentials: true,
      });

      if (data.status_code === 1) {
        return fulfillWithValue([]);
      }

      return fulfillWithValue(data.reviews);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for submitting a review
export const submitReview = createAsyncThunk(
  "reviews/submitReview",
  async (reviewData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/reviews/submit_review", reviewData, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching average rating
export const fetchAverageRating = createAsyncThunk(
  "reviews/fetchAverageRating",
  async (productId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/ratings/fetch_avg_rating/${productId}`, {
        withCredentials: true,
      });

      return data.result.averageRating;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching average rating"
      );
    }
  }
);

const reviewReducer = createSlice({
  name: "reviews",
  initialState: {
    reviews: [],
    status: "idle",
    error: null,
    submitStatus: "idle",
    submitError: null,
    averageRating: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch reviews
      .addCase(fetchReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews = action.payload;
        if (action.payload.length === 0) {
          state.error = "Product does not have a review";
        } else {
          state.error = null;
        }
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Submit review
      .addCase(submitReview.pending, (state) => {
        state.submitStatus = "loading";
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.submitStatus = "succeeded";
        state.reviews.push(action.payload);
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.submitStatus = "failed";
        state.submitError = action.payload;
      })
      .addCase(fetchAverageRating.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAverageRating.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.averageRating = action.payload;
      })
      .addCase(fetchAverageRating.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default reviewReducer.reducer;
