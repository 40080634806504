// import axios from "axios";

// const api = axios.create({
//   baseURL: "api/market_place",
//   headers: {
//     "x-api-key": "2aa1513c-8998-454e-9d52-fa95b47fb142",
//   },
// });

// export default api;

import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,

  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

export default api;
