import { useEffect, useState } from "react";

const ProductRating = () => {
  const [averageRating, setAverageRating] = useState(null);

  return (
    <div className="col-span-12 sm:col-span-3">
      <div className="bg-white rounded-lg">
        <div className="flex justify-between px-5 pt-5 pb-4 border-b border-borderLine">
          <p className="text-sm font-baskerVille">Products Rating</p>
        </div>
        <div className="p-5">
          <div className="pt-4">
            <div>
              <span className="text-2xl font-semibold text-blue font-baskerVille">
                {averageRating !== null ? averageRating : "0"}
              </span>
              <span className="text-sm font-medium ms-1">out of 5</span>
            </div>
            <span className="text-sm italic font-medium text-black/50">
              123 people rated this product
            </span>
            <div className="pt-8">
              <div className="flex items-center gap-4 pb-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130"
                    height="30"
                    viewBox="0 0 158 30"
                    fill="none"
                  >
                    <path
                      d="M13.0979 5.8541C13.6966 4.01148 16.3034 4.01148 16.9021 5.8541L17.9187 8.98278C18.1864 9.80682 18.9543 10.3647 19.8208 10.3647H23.1105C25.0479 10.3647 25.8535 12.844 24.2861 13.9828L21.6246 15.9164C20.9237 16.4257 20.6304 17.3284 20.8981 18.1525L21.9147 21.2812C22.5134 23.1238 20.4044 24.656 18.837 23.5172L16.1756 21.5836C15.4746 21.0743 14.5254 21.0743 13.8244 21.5836L11.163 23.5172C9.59559 24.656 7.48663 23.1238 8.08533 21.2812L9.1019 18.1525C9.36965 17.3284 9.07633 16.4257 8.37536 15.9164L5.71395 13.9828C4.14652 12.844 4.95207 10.3647 6.88952 10.3647H10.1792C11.0457 10.3647 11.8136 9.80682 12.0813 8.98278L13.0979 5.8541Z"
                      fill="#FFD700"
                    />
                    <path
                      d="M45.0979 5.8541C45.6966 4.01148 48.3034 4.01148 48.9021 5.8541L49.9187 8.98278C50.1864 9.80682 50.9543 10.3647 51.8208 10.3647H55.1105C57.0479 10.3647 57.8535 12.844 56.2861 13.9828L53.6246 15.9164C52.9237 16.4257 52.6304 17.3284 52.8981 18.1525L53.9147 21.2812C54.5134 23.1238 52.4044 24.656 50.837 23.5172L48.1756 21.5836C47.4746 21.0743 46.5254 21.0743 45.8244 21.5836L43.163 23.5172C41.5956 24.656 39.4866 23.1238 40.0853 21.2812L41.1019 18.1525C41.3697 17.3284 41.0763 16.4257 40.3754 15.9164L37.714 13.9828C36.1465 12.844 36.9521 10.3647 38.8895 10.3647H42.1792C43.0457 10.3647 43.8136 9.80682 44.0813 8.98278L45.0979 5.8541Z"
                      fill="#FFD700"
                    />
                    <path
                      d="M77.0979 5.8541C77.6966 4.01148 80.3034 4.01148 80.9021 5.8541L81.9187 8.98278C82.1864 9.80682 82.9543 10.3647 83.8208 10.3647H87.1105C89.0479 10.3647 89.8535 12.844 88.2861 13.9828L85.6246 15.9164C84.9237 16.4257 84.6304 17.3284 84.8981 18.1525L85.9147 21.2812C86.5134 23.1238 84.4044 24.656 82.837 23.5172L80.1756 21.5836C79.4746 21.0743 78.5254 21.0743 77.8244 21.5836L75.163 23.5172C73.5956 24.656 71.4866 23.1238 72.0853 21.2812L73.1019 18.1525C73.3697 17.3284 73.0763 16.4257 72.3754 15.9164L69.714 13.9828C68.1465 12.844 68.9521 10.3647 70.8895 10.3647H74.1792C75.0457 10.3647 75.8136 9.80682 76.0813 8.98278L77.0979 5.8541Z"
                      fill="#FFD700"
                    />
                    <path
                      d="M109.098 5.8541C109.697 4.01148 112.303 4.01148 112.902 5.8541L113.919 8.98278C114.186 9.80682 114.954 10.3647 115.821 10.3647H119.11C121.048 10.3647 121.853 12.844 120.286 13.9828L117.625 15.9164C116.924 16.4257 116.63 17.3284 116.898 18.1525L117.915 21.2812C118.513 23.1238 116.404 24.656 114.837 23.5172L112.176 21.5836C111.475 21.0743 110.525 21.0743 109.824 21.5836L107.163 23.5172C105.596 24.656 103.487 23.1238 104.085 21.2812L105.102 18.1525C105.37 17.3284 105.076 16.4257 104.375 15.9164L101.714 13.9828C100.147 12.844 100.952 10.3647 102.89 10.3647H106.179C107.046 10.3647 107.814 9.80682 108.081 8.98278L109.098 5.8541Z"
                      fill="#FFD700"
                    />
                    <path
                      d="M141.098 5.8541C141.697 4.01148 144.303 4.01148 144.902 5.8541L145.919 8.98278C146.186 9.80682 146.954 10.3647 147.821 10.3647H151.11C153.048 10.3647 153.853 12.844 152.286 13.9828L149.625 15.9164C148.924 16.4257 148.63 17.3284 148.898 18.1525L149.915 21.2812C150.513 23.1238 148.404 24.656 146.837 23.5172L144.176 21.5836C143.475 21.0743 142.525 21.0743 141.824 21.5836L139.163 23.5172C137.596 24.656 135.487 23.1238 136.085 21.2812L137.102 18.1525C137.37 17.3284 137.076 16.4257 136.375 15.9164L133.714 13.9828C132.147 12.844 132.952 10.3647 134.89 10.3647H138.179C139.046 10.3647 139.814 9.80682 140.081 8.98278L141.098 5.8541Z"
                      fill="#FFD700"
                    />
                  </svg>
                </span>
                <span className="text-sm font-semibold">123 ratings</span>
              </div>
              <p className="text-xs italic font-medium text-black/50">
                Your feedback matters to us!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRating;
