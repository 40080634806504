import homeReducer from "./homeReducer";
import productReducer from "./productReducer";
import reviewReducer from "./reviewReducer";
import cartReducer from "./cartReducer";
import authReducer from "./authReducer";
import shippingReducer from "./shippingReducer";
import paymentReducer from "./paymentReducer";

const rootReducer = {
  home: homeReducer,
  auth: authReducer,
  product: productReducer,
  reviews: reviewReducer,
  cart: cartReducer,
  shipping: shippingReducer,
  payment: paymentReducer,
};
export default rootReducer;
